@font-face {
    font-family: knockout;
    src: url('../fonts/knockout.otf');
}

body {
    font-family: Open Sans, Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 100%;
}

/* Navbar */

/*

.navbar {
    border-radius:0;
    -moz-border-radius:0;
    -webkit-border-radius:0;
    border:0;
    margin:0;
}

.navbar-brand {
    width:100px;
}

.navbar ul {
    float:left;
}

.navbar li {
}

.navbar a {
    font-size:18px;
}

.nav > li > a:hover {
    color:#eee;
    text-decoration: underline;
}

.navbar .dropdown.open a {
    background:transparent !important;
    box-shadow: none;
}

.dropdown-menu > li > a:hover {
    text-decoration: underline;
}

*/

/****************/

nav {
}

.navbar {
    float:right;
    margin-bottom:0;
}

.navbar-header {
    float: none;
}
.navbar-toggle {
    display: block;
}
.navbar-collapse {
    border-top: 3px solid #535353 !important;
    border-bottom: 3px solid #535353 !important;
    position:absolute;
    z-index:100000;
    background: white none repeat scroll 0 0;
    right:0;
}
.navbar-collapse.collapse {
    display: none!important;
}
.navbar-collapse.in {
    display: block!important;
}
.navbar-nav {
    float: none!important;
    margin: 7px -15px;
    background:white;
    padding:0;
    text-align:center;
    width:200px;
}
.navbar-nav>li {
    float: none;
}
.navbar-nav > li > a {
    padding-top: 3px;
    padding-bottom: 3px;
    color:black !important;
    font-size:16px !important;
    font-weight:700;
}

.navbar-default .navbar-toggle {
    border:0;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #535353;
}

.navbar-default .navbar-toggle {
    border:0;
}
.navbar-default .navbar-toggle:hover {
    background:none;
}

.navbar-toggle .icon-bar {
    border-radius: 1px;
    display: block;
    height: 3px;
    width: 30px;
    float:right;
    clear:both;
}

.navbar-toggle.collapsed:hover .icon-bar:nth-child(2) {
    width:40px;
}

.navbar-toggle.collapsed:hover .icon-bar:nth-child(3) {
    width:35px;
}

.navbar-toggle.collapsed:hover .icon-bar:nth-child(4) {
    width:43px;
}

.icon-bar {
    -webkit-transition: all 300ms cubic-bezier(0.175,0.885,0.32,1.275);
    -moz-transition: all 300ms cubic-bezier(0.175,0.885,0.32,1.275);
    -ms-transition: all 300ms cubic-bezier(0.175,0.885,0.32,1.275);
    -o-transition: all 300ms cubic-bezier(0.175,0.885,0.32,1.275);
}

.navbar-toggle {
    margin-right:0;
}

.navbar-toggle .icon-bar {
    width: 30px;
    transition: all 0.2s;
}
.navbar-toggle .top-bar {
    transform: rotate(40deg);
    transform-origin: 15% 10%;
}
.navbar-toggle .middle-bar {
    opacity: 0;
}
.navbar-toggle .bottom-bar {
    transform: rotate(-40deg);
    transform-origin: 10% 90%;
}

.navbar-toggle.collapsed .top-bar {
    width:30px;
    transform: rotate(0);
}
.navbar-toggle.collapsed .middle-bar {
    width:30px;
    opacity: 1;
}
.navbar-toggle.collapsed .bottom-bar {
    width:30px;
    transform: rotate(0);
}

.main-navbar .col-sm-2, .main-navbar .col-sm-6 {
    padding-left:5px;
    padding-right:5px;
}

.nav-button {
    border-radius: 5px;
    display: block;
    padding: 5px;
    position:relative;
    cursor:pointer;
}

.nav-button img {
    margin: 0 auto;
    max-height: 75px;
    cursor:pointer;
}

.nav-button:hover {
    text-decoration:none;
}

.nav-button-blur {
    background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
    border-radius: 5px;
    display: none;
    height: 86px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.navbar a:hover,.navbar a:focus {
    color:#606e8d !important;
}

.logout-navbar {
    margin-top: 15px;
}

.logout-navbar-logo {
    float:left;
}

.logout-navbar-logo img {
    height:26px;
}

.logout-navbar ul {
    float:right;
}

.logout-navbar li {
    float:right;
    margin-left:10px;
    list-style:none;
}

.navbar-category {
    background: #eee;
    padding:3px;
    border:1px solid #777;
    margin-top:10px;
}

.divider {
    border-bottom: 1px solid black;
    margin-top: 10px;
}

.navbar-register {
    background: #a4ce37 none repeat scroll 0 0;
    border-radius: 3px;
    color: white !important;
    margin-top: 10px;
    padding: 5px 10px !important;
}

.navbar-register:hover {
    background:#333 !important;
    text-decoration: none !important;
}

.navbar-login {
    background: #777 !important;
    margin-right:0 !important;
    color:white !important;
    border-radius: 3px;
    padding: 5px 10px !important;
}

.navbar-login:hover {
    text-decoration: none !important;
    background:#333 !important;
    color:white !important;
}

/* Main Content */

.header {
    border-bottom:1px solid #e0e0e0;
    right: 0;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
}

.main-content {
    padding:0px 40px 40px;
    min-height:700px;
}

.main-logo {
    background: white none repeat scroll 0 0;
    border-radius: 3px;
    float: left;
    height: 50px;
    padding: 10px;
}

.main-logo img {
    max-height: 100%;
}

.main-button {
    display: table;
    margin:0 auto;
    padding:5px 46px;
    color:white;
    border: 0;
    border-radius:6px;
}

.main-button:hover {
    background:#333;
    border-bottom:4px solid #191919;
    color:white;
}

.main-small-button {
    display: table;
    margin:0 auto;
    padding:1px 12px;
    color:white;
    border: 0;
    border-radius:6px;
    font-size:12px;
}

.main-small-button:hover {
    background:#333;
    border-bottom:4px solid #191919;
    color:white;
}

.main-table-button {
    background: #a4ce37 none repeat scroll 0 0;
    border: 0 none;
    color: white;
    display: inline-block;
    padding: 1px 14px;
}

.main-table-button:hover {
    background:#333;
    color:white;
    border-bottom:4px solid #191919;
}

.main-table-filter-wrapper {
    background: white none repeat scroll 0 0;
    border-radius: 10px;
    color: #535353;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 7px 5px 2px;
    border:1px solid #535353;
}

.main-table-filter-wrapper label {
    text-decoration:underline;
}

.main-table-filter-wrapper label,.main-table-filter-content {
    margin-right:3px;
}

.main-table-all-filters-wrapper {
    margin-bottom:20px;
}

.main-table-all-filters {
    float:left;
}

.main-table-filter-button-wrapper {
    display:none;
    float:left;
}

.main-table-filter-content {
    display:inline-block;
}

.main-table-filter-remove {
    cursor:pointer;
    font-weight:bold;
}

.main-table-filter-remove:hover {
    color:black;
}

.main-table-filter-wrapper {
    margin-right:5px;
}

.main-table-inner-square .img-responsive {
    max-height:20px;
}

.main-table-inner-square div {
    word-wrap: break-word;
}

.main-button-blue {
    background: #5bc0de;
    border-bottom: 4px solid #51a8c2;
}

.store-all-offers{
    margin-bottom: 20px;
}

.main-button-grey {
    background: #777;
    border-bottom: 4px solid #5e5d5d;
}

.main-button-green {
    background:#a4ce37;
    border-bottom: 4px solid #85a72c;
}

.main-button-green-simple {
    background:#a4ce37;
}

.main-button-red {
    background:#e14342;
    border-bottom: 4px solid #c73b3a;
}

.main-button-action {
    background: #a4ce37 none repeat scroll 0 0;
    border-radius: 3px;
    color: white;
    display: table;
    font-size: 17px;
    margin: 23px auto 0;
    padding: 2px 15px;
    border:0;
    font-weight:bold;
}

.main-button-action:hover {
    background:#333;
    color:white;
    text-decoration: none;
}

.main-button-action-2 {
    background: #2969b0 none repeat scroll 0 0;
    border: 0 none;
    border-radius: 3px;
    color: white;
    display: table;
    font-size: 17px;
    font-weight: bold;
    margin: 23px auto 20px;
    padding: 2px 20px;
    width: 100%;
}

.main-button-action-2:hover {
    background:#333;
    color:white;
    text-decoration: none;
}

/* Sidebar */

/* Home */

.home-main {
    text-align:center;
}

.home-main h1{
    color:white;
    font-size:100px;
}

.home-main h2{
    color:white;
    font-size:30px;
}

.home-white {

}

.home-grey {
    background: #ebe8df;
}

.home-design-wrapper {
    max-width:1048px;
    margin:0 auto;
    text-align: center;
}

.home-design-wrapper h2 {
    font-size:60px;
    color:#d20c2c;
}

.home-design-wrapper p {
    font-size:23px;
    color:#797979;
    line-height:32px;
}

.home-design-text-wrapper {
    padding-top:100px;
}

.home-design-wrapper img {
    margin:0 auto;
}

.home-animation-1-action,.home-animation-2-action,.home-animation-3-action,.home-animation-4-action,.home-animation-5-action,.home-animation-6-action {
    position:relative;
    opacity: 0;
}

@-webkit-keyframes bounceInLeft {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@keyframes bounceInLeft {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes bounceInRight {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@keyframes bounceInRight {
    from, 60%, 75%, 90%, to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.home-tada {
    -webkit-animation-name: tada;
    animation-name: tada;
    animation-duration: 2s;
}

.home-bounce-right {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}


.home-bounce-left {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.home-zoom {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

/* Inner Pages */

.inner-pages-header h1 {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 40px;
    text-align: center;
}

.inner-pages-header h2 {
    background: #535353 none repeat scroll 0 0;
    border-radius: 6px;
    color: white;
    font-size: 23px;
    line-height: 28px;
    margin: 0 auto 50px;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.inner-pages-wrapper {

}

.inner-pages-wrapper p {
    font-size:17px;
    margin-bottom:40px;
}

/* Login */

.login-forgot-pass {
    float:right;
}

/* Profile */

.profile-resend {

}

.profile-resend-loading {
    background: white none repeat scroll 0 0;
    border-radius: 5px;
    display: none;
    padding: 10px;
    text-align: center;
}

.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
    font-size: 28px;
    position: relative;
    top: -2px;
}

@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

/* Hacks */
.alert li {
    list-style: none;
}

.main-content .alert {
    margin-top: 20px;
}

.ui-datepicker-month,.ui-datepicker-year {
    color:black;
}

.form-horizontal .form-group {
    margin-left:0;
    margin-right:0;
}

a:focus {
    outline: 0;
}

.fancybox-inner {
    text-align:center;
}

.pager li a, .pager .disabled span {
    background: #a4ce37 none repeat scroll 0 0;
    border: 0 none;
    color: white;
    font-size: 46px;
    line-height: 24px;
    padding: 10px 20px 18px 21px;
}

.pager li a:hover,.pagination li a:hover {
    background: #535353;
    color:white;
}

.pager .disabled span,.pagination .disabled span {
    background: #eee;
}

.pagination li a,.pagination li span {
    background: #eee none repeat scroll 0 0;
    color: #777;
    border: 1px solid #ddd;
}

.pagination .active span {
    background: #a4ce37;
    border: 1px solid #ddd;
}

.canvasjs-chart-credit {
    display: none;
}

.btn {
    color:white;
}

.fa-question-circle {
    cursor:pointer;
}

/* Footer */

footer {
    background:white;
    text-align:center;
}

footer ul {
    margin:0;
    padding:0;
    display:inline-block;
}

footer li {
    float: left;
    font-size: 20px;
    list-style: outside none none;
}

footer a {
    color:#4b4b4b;
    font-size:18px;
    margin: 0 10px;
}

footer .row {
    border-top:2px solid #ededed;
    padding:20px;
}

/* Contact Us */
.contact-wrapper {
    width:80%;
    margin:0 auto;
    margin-top:30px;
}

.contact-wrapper .btn {
    display: block;
    padding: 20px 70px;
    text-align: center;
}

/* Payment Details */
.payment-details-paypal, .payment-details-bank-transfer {
    display:none;
}

/* Apps */

.app-empty {
    background: #eee none repeat scroll 0 0;
    border: 1px solid #eee;
    border-radius: 3px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 60px;
    padding: 100px 50px;
}

.app-squares-wrapper {
    margin-top: 40px;
}

.app-square-wrapper {
    padding: 0;
}

.app-square {
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 #aebed1;
    margin-bottom: 20px;
}

.app-inner-square {
    padding:20px
}

.app-square-wrapper:nth-child(odd) .app-square {
    margin-right:10px;
}

.app-square-wrapper:nth-child(even) .app-square {
    margin-left:10px;
}

.app-name {
    background: #eee none repeat scroll 0 0;
    border-radius: 3px 3px 0 0;
    color: black;
    font-size: 24px;
    padding: 7px 18px;
}

.app-currency-icon-wrapper {
    margin-top:40px;
}

/* Reports */
.reports-wrapper h3 {
    margin-bottom: 20px;
}

.reports-date-line {
    margin-bottom:30px;
}

.reports-summary-wrapper,.reports-network-wrapper {
    padding-bottom:20px;
}

.reports-summary-data {
    font-weight:bold;
    font-size:18px;
}

.reports-summary-label {

}
.reports-summary-header {
    padding-left:30px;
    padding-bottom:10px;
}

#offer-breakdown,#app-breakdown,#network-breakdown {
    height: 400px;
    overflow: scroll;
    clear:both;
}

#app-breakdown th,#offer-breakdown th,#network-breakdown th {
    background:#a4ce37;
    color:white;
}
#app-breakdown th,#app-breakdown td,#offer-breakdown th,#offer-breakdown td,#network-breakdown th,#network-breakdown td {
    padding: 10px 10px;
}

#app-breakdown th:first-child,#app-breakdown td:first-child,#offer-breakdown th:first-child,#offer-breakdown td:first-child,#network-breakdown th:first-child,#network-breakdown td:first-child {
    width: 50%;
}

#app-breakdown a,#offer-breakdown a,#network-breakdown a {
    font-weight:bold;
    color:black;
    text-decoration:underline;
}

#app-breakdown tr:nth-child(even),#offer-breakdown tr:nth-child(even),#network-breakdown tr:nth-child(even) {
    background: #F6F6F6
}
#app-breakdown tr:nth-child(odd),#offer-breakdown tr:nth-child(odd),#network-breakdown tr:nth-child(odd) {
    background: #E8E8E8
}

/* Main table */

.main-table-empty {
    background: #eee none repeat scroll 0 0;
    border: 1px solid #eee;
    border-radius: 3px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 60px;
    padding: 30px;
}

.main-table-squares-wrapper {
    margin-top: 40px;
}

.main-table-square-wrapper {
    padding: 0;
}

.main-table-label {
    font-weight:bold;
}

.main-table-square {
    background: rgba(238, 238, 238, 0.35) none repeat scroll 0 0;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.125);
    margin-bottom: 9px;
    padding: 0;
}

.main-table-inner-square {
    padding:10px
}

.main-table-name {
    color: #a4ce37;
    font-weight: bold;
}

.main-table-controls {
    display: inline-block;
    width: 30px;
    float:left;
}

.main-table-controls a{
    color: #535353;
    margin-right:5px;
}

.main-table-controls a:hover{
    text-decoration: none;
    color: #870a0a;
}

.main-table-filters {
    background: #535353 none repeat scroll 0 0;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 20px 0;
}

.main-table-total {
    background: #eee none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: table;
    padding: 10px;
}

.form-control.main-table-sort {
    width: 70%;
}

.form-control.main-table-sort-dir {
    width: 30%;
}

.main-form {
    padding-top:40px;
}

.main-table-all-filter-col {
    float:left;
}

.main-table-sort-col {
    float:right;
}

/* Clicks */
.clicks-manual-credit {
    color:darkred;
}

/* Home */
.home-banner-photo {
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    background:black;
}

.home-slogan {
    color: white;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 32%;
}

.home-wrapper h1 {
    font-size:73px;
    margin-bottom:10px;
}

.home-wrapper h2 {
    font-size: 38px;
    margin-top: 0;
}

.home-wrapper h3 {
    font-size:56px;
}

.home-wrapper h4 {
    font-size: 40px;
    line-height: 42px;
}

.home-wrapper section {
    padding:60px 10px;
    font-family:knockout;
}

.home-banner-grey p,.home-banner-white p,.home-banner-phone p {
    font-size:24px;
}

.home-banner-green {
    background:#a4ce37;
}

.home-banner-grey {
    background: #f3f3f3 none repeat scroll 0 0;
}

.home-banner-phone {
    background: #282f36 url("../../img/home-iphone.jpg") no-repeat scroll center bottom / cover ;
    color:white;
}

.home-big-white-title {
    font-size:75px;
    color:white;
}

.home-medium-white-title {
    font-size:55px;
    color:white;
}

.home-small-white-title {
    font-size:35px;
    color:white;
}

.home-computers-img {
    margin-top:50px;
}

.home-button {
    background: #a4ce37 none repeat scroll 0 0;
    border-radius: 3px;
    color: white;
    margin-top: 30px;
    padding: 20px 70px;
    font-size:40px;
    display:inline-block;
}

.home-button:hover {
    background:#333;
    color:white;
    text-decoration: none;
}

.home-round-icon {
    background: white none repeat scroll 0 0;
    border-radius: 30px;
    color: #282f36;
    display: inline-block;
    height: 40px;
    padding-top: 5px;
    width: 40px;
    font-size: 22px;
}

/* User Offers */

.offers-app-label {
    font-weight:bold;
    text-decoration:underline;
    font-size:20px;
    float:left;
    margin-right:30px;
    clear:both;
}

/* Mass Offers */

.mo-offer {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 5px;
    position:relative;
}

.mo-offer .form-group {
    margin-bottom:5px;
}

.mo-description {
    height: 160px !important;
}

.mo-countries-allowed {
    height: 100px !important;
}

.mo-thumbnail-image {
    height:50px;
}

.mo-loading {
    display:none;
    text-align:center;
}

.mo-status {
    display:none;
}

.mo-countries-allowed {
    display:block;
}

.mo-main-content .multiselect-container {
    height: 260px;
    overflow: scroll;
}

.mo-message {
    height: 300px !important;
}

.mo-submit {
    margin-top: 40px;
}

.mo-offers {
    margin-top: 40px;
}

.mo-create {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mo-hidden {
    position:absolute;
    top:5px;
    right:5px;
    color:black;
}

.mo-hidden:hover {
    color:mediumpurple;
    text-decoration:none;
}

/* Postback test */

.postback-loading {
    display: none;
    padding:30px;
}

/* User History */

.uh-main-content {

}

.uh-user {
    float:left;
    margin:10px;
    max-height:320px;
    overflow:hidden;
}

.uh-data-wrapper {
    border:1px solid #eee;
    overflow:scroll;
    width:350px;
    height:300px;
    padding:5px;
}

.uh-username {
    font-weight:bold;
    font-size:15px;
}

.uh-page {
    display:inline-block;
    font-weight:bold;
}
.uh-post-vars {
    display:inline-block;
}
.uh-created-at {
    float:right;
    font-weight:bold;
}

.uh-username,.uh-user-from,.uh-user-level {
    display:inline-block;
}

.uh-green-ball {
    width:15px;
    height:15px;
    border-radius:15px;
    background:green;
    display:none;
}

.uh-green-ball-wrapper {
    display:inline-block;
    margin-left:10px;
}

.uh-row {
    background: #eee none repeat scroll 0 0;
    margin-bottom: 5px;
}

.uh-new {
    color:red;
}

.uh-main-top {
    max-height:800px;
    width:100%;
    border: 4px solid #eee;
}

/* Docs */

.docs-table {

}

.docs-table th {
    font-weight:bold;
}

.docs-table td,.docs-table th {
    padding:18px 12px;
    border:1px solid #C9C9C9;
}

/* Exclusion */

.exclusion-offer-countries-wrapper {
    display:none;
}

/* Live Search */

.live-search-wrapper {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #eeeeee;
    border-radius: 0 0 3px 3px;
    min-height: 80px;
}

.live-search-result {
    margin:3px 0;
    display:block;
}

/* Show Wall */

.show-wall .wall {
    margin:30px;
}

.show-wall .wall-row {
    border: 1px solid black;
    margin-bottom:40px;
    padding:20px;
}

.show-wall img {
    max-height: 50px;
    max-width: 100px;
    margin: 0 auto;
    display: block;
}

@media(max-width:767px){
    .header {
        position:relative !important;
        background: white !important;
    }

    .main-logo {
        float:none;
        margin:0 auto;
        text-align:center;
    }

    .main-logo img {
        margin:0 auto;
    }

    .main-wrapper {
        padding:0;
    }

    .navbar {
        box-shadow: none;
        margin:0;
        border:0;
        width:100%;
    }

    .navbar ul {
        text-align:center;
        padding:0;
    }

    .navbar li {
        padding:0;
        float:none;
    }

    .navbar a {
        float:none;
    }

    .navbar-nav > li > a {
        font-size:18px !important;
    }

    .navbar-nav {
        width:100%;
    }

    .navbar-default {
        background: transparent;
    }

    .navbar-toggle {
    }

    .navbar-brand {
        float:left !important;
    }

    .navbar-brand img {
        height: 26px;
    }

    .navbar-collapse {
        position: static;
        width: 100%;
        margin-top:20px;
    }

    .logout-navbar {
        margin: 20px 20px 10px;
    }

    .main-content {
        padding:5px 5px 40px 5px;
        min-height:auto;
    }

    .main-table-controls {
        float:none;
    }

    .main-table-label {
        margin-top:15px;
    }

    .main-table-button {
        width:100%;
        font-size:20px;
        padding:10px;
        margin-bottom:5px;
    }

    .main-table-buttons {
        margin:20px 0;
        clear:both;
    }

    .main-table-inner-square {
        text-align: left;
        margin-bottom:40px;
    }

    .main-table-controls .glyphicon {
        font-size:30px;
    }

    .main-button {
        width:100%;
    }

    .main-table-name {
        font-size:30px;
        margin-bottom:20px;
    }

    .main-table-actions-wrapper {
        margin-top:40px;
    }

    .main-table-filter-button-wrapper {
        width:100%;
    }

    .form-control.main-table-sort {
        width: 70%;
    }

    .form-control.main-table-sort-dir {
        width: 30%;
    }

    .main-table-sort-wrapper {
        margin-bottom:20px;
    }

    .main-table-all-filter-col,.main-table-sort-col {
        float:none;
    }

    .inner-pages-header {
        padding:0;
    }

    .home-slogan {
        top: 28%;
    }

    .home-wrapper h1 {
        font-size: 53px;
    }

    .home-wrapper h2 {
        font-size: 28px;
        margin-top: 0;
    }

    .home-banner-photo img{
        height:300px;
        max-width: none;
    }

    .home-banner-phone .col-sm-4 {
        margin-bottom:40px;
    }

    footer ul {
        text-align:center;
    }

    footer li {
        float:none;
        display:inline-block;
        margin-bottom:20px;
    }

    .label {
        font-size:20px;
        display: block;
    }

    .dropdown-menu {
        border: 0 none;
        box-shadow: none;
    }

    .reports-date-line button {
        margin-top:20px;
    }

    .reports-date-line span {
        font-size:12px;
    }
}
@media(min-width:768px){
    .main-table-filter-wrapper {
        display:block;
    }

    .navbar-default {
        background:none !important;
        border: 0 !important;
        box-shadow: none !important;
    }

    .navbar a {
        padding-right: 5px !important;
        padding-left: 5px !important;
        font-size:14px;
    }
}
@media(min-width:992px){
    .navbar a {
        padding-right: 10px !important;
        padding-left: 10px !important;
        font-size:18px;
    }

    .main-table-filter-wrapper {
        display:inline-block;
    }
}
@media(min-width:1200px){

}